import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ComingSoonPage = () => (
  <Layout>
    <SEO title="Home" />
    <main className="vh-100 flex justify-center items-center">
        <h1 className="text-white">
            COMING SOON
        </h1>
    </main>
  </Layout>
)

export default ComingSoonPage
